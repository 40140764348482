$(".si-modal").each(function (elem) {
  const modal = this;

  const _role = $(modal).attr("role");
  const _labelledBy = $(modal).attr("id");

  if (_role === "dialog") {
    const closeTrigger = $(".si-modal [data-dismiss='modal']");

    closeTrigger.each(function (index) {
      $(closeTrigger[index]).on("click", function () {
        $(modal).removeClass("si-modal--show");
      });
    });

    const trigger = $(`[data-target="#${_labelledBy}"]`);

    trigger.each(function (index) {
      $(trigger[index]).on("click", function () {
        $(modal).addClass("si-modal--show");
      });
    });
  }
});
